// import React from 'react';
// import { FaSearch, FaUserAltSlash, FaTimes, FaSpinner } from 'react-icons/fa';
// import { ImSpinner8 } from 'react-icons/im';
// import { Link } from 'react-router-dom';
// import useSearchAndFollow from './useSearchAndFollow';

// const Search = () => {
//   const {
//     inputValue,
//     handleInputChange,
//     clearInput,
//     users,
//     loadingUsers,
//     error,
//     handleFollowOrUnfollow,
//     loadingFollow,
//   } = useSearchAndFollow();

//   return (
//     <div className="max-w-full md:max-w-xl mx-auto h-screen flex flex-col md:flex-row">
//       <div className="flex justify-center flex-grow bg-neutral-800 text-white p-4 md:p-2 rounded-md flex-col">
//         <div className="bg-black rounded-lg flex flex-col flex-grow shadow-lg">
//           {/* Search Input UI */}
//           <div className="relative mx-4 mt-4 md:mx-6">
//             <input
//               type="text"
//               value={inputValue}
//               onChange={handleInputChange}
//               placeholder="Search..."
//               className="w-full p-2 pl-10 pr-10 border-none rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 ease-in-out"
//             />
//             <FaSearch className="absolute top-3 left-3 text-gray-400" />
//             {inputValue && (
//               <FaTimes
//                 className="absolute top-3 right-3 text-gray-400 cursor-pointer"
//                 onClick={clearInput}
//               />
//             )}
//           </div>

//           {error && <p className="text-red-500 text-center mx-4 mt-4">{error}</p>}

//           {/* User List UI */}
//           <div className="flex-grow overflow-y-auto mx-4 mt-4 mb-4 md:mx-6 h-64">
//             {loadingUsers ? (
//               <div className="flex items-center justify-center h-full">
//                 <ImSpinner8 className="text-gray-400 animate-spin" size={40} />
//               </div>
//             ) : users.length === 0 ? (
//               <div className="flex flex-col items-center justify-center h-full">
//                 <FaUserAltSlash className="text-gray-400 animate-bounce" size={60} />
//                 <p className="text-gray-400 mt-4 animate-pulse">No recent searches.</p>
//               </div>
//             ) : (
//               users.map((user) => (
//                 <div
//                   key={user.id}
//                   className=" rounded-lg p-2 px-4 flex items-center justify-between mb-3 shadow-md"
//                 >
//                   <div className="flex items-start gap-4">
//                     <img
//                       src={user.profilePicture || "https://parkridgevet.com.au/wp-content/uploads/2020/11/Profile-300x300.png"}
//                       alt=""
//                       className="block h-12 w-12 rounded-full object-cover"
//                     />
//                     <Link to={`/user/profile/${user.id}`}>
//                       <div className="h-full flex flex-col items-start">
//                         <span className="text-lg font-semibold text-white">{user.userName}</span>
//                         <span className="text-sm text-gray-300">{user.firstName} {user.lastName}</span>
//                       </div>
//                     </Link>
//                   </div>
//                   <button
//                     className={`ml-2 px-4 py-1.5 font-semibold text-sm rounded-md text-center transition-colors duration-300 ease-in-out ${
//                       user.isFollowing ? "bg-gray-500 text-white" : "bg-blue-600 text-white"
//                     }`}
//                     onClick={() => handleFollowOrUnfollow(user.id, user.isFollowing)}
//                     disabled={loadingFollow[user.id]}
//                   >
//                     {loadingFollow[user.id] ? (
//                       <FaSpinner className="animate-spin" />
//                     ) : (
//                       user.isFollowing ? "Following" : "Follow"
//                     )}
//                   </button>
//                 </div>
//               ))
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Search;









// import React from 'react';
// import { FaSearch, FaTimes, FaSpinner, FaUserAltSlash } from 'react-icons/fa';
// import useSearchAndFollow from './useSearchAndFollow';
// import { Link } from 'react-router-dom';

// const Search = () => {
//   const {
//     inputValue,
//     handleInputChange,
//     clearInput,
//     users,
//     loadingUsers,
//     error,
//     handleFollowOrUnfollow,
//     loadingFollow,
//   } = useSearchAndFollow();

//   return (
//     <div className="max-w-xl mx-auto h-screen flex flex-col">
//       <div className="flex justify-center flex-grow bg-neutral-800 text-white p-4 md:p-2 rounded-md flex-col">
//         <div className="bg-black rounded-lg flex flex-col flex-grow shadow-lg">
//           {/* Search Input UI */}
//           <div className="relative mx-4 mt-4 md:mx-6">
//             <input
//               type="text"
//               value={inputValue}
//               onChange={handleInputChange}
//               placeholder="Search..."
//               className="w-full p-2 pl-10 pr-10 border-none rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300 ease-in-out"
//             />
//             <FaSearch className="absolute top-3 left-3 text-gray-600 animate-pulse" />
//             {inputValue && (
//               <FaTimes
//                 className="absolute top-3 right-3 text-gray-600 cursor-pointer"
//                 onClick={clearInput}
//               />
//             )}
//           </div>

//           {/* Error Handling */}
//           {error && <p className="text-red-500 text-center mx-4 mt-4">{error}</p>}

//           {/* User List */}
//           <div className="flex-grow overflow-y-auto mx-4 mt-4 mb-4 md:mx-6 h-64">
//             {loadingUsers ? (
//               <div className="flex items-center justify-center h-full">
//                 <FaSpinner className="text-gray-400 animate-spin" size={40} />
//               </div>
//             ) : users.length === 0 ? (     
//               <div className="flex flex-col items-center justify-center h-full">
//                   <FaUserAltSlash className="text-gray-400 animate-bounce" size={60} />
//                 <p className="text-gray-400 mt-4">No users found.</p>
//               </div>
//             ) : (
//               users.map((user) => (
//                 <div key={user.id}
//                   className="rounded-lg p-2 px-4 flex items-center justify-between mb-3 shadow-md"
//                 >
//                   <div className="flex items-start gap-4 cursor-pointer">
//                     <img
//                       src={user.profilePicture || 'https://parkridgevet.com.au/wp-content/uploads/2020/11/Profile-300x300.png'}
//                       alt={user.userName}
//                       className="h-12 w-12 rounded-full object-cover"
//                     />
//                     <Link to={`/user/profile/${user.id}`}>
//                       <div className="h-full flex flex-col items-start">
//                         <span className="text-md  text-white">{user.userName}</span>
//                         <span className="text-xs text-gray-300">{user.firstName} {user.lastName}</span>
//                       </div>
//                     </Link>
//                   </div>
//                   <button
//                     className={`ml-2 px-4 py-1.5 border text-sm rounded-md transition-colors duration-300 ease-in-out ${
//                       user.isFollowing ? 'bg-gray-500 text-white' : 'bg-[#56b5f4] text-white'
//                     }`}
//                     onClick={() => handleFollowOrUnfollow(user.id, user.isFollowing)}
//                     disabled={loadingFollow[user.id]}
//                   >
//                     {loadingFollow[user.id] ? (
//                       <FaSpinner className="animate-spin" />
//                     ) : (
//                       user.isFollowing ? 'Following' : 'Follow'
//                     )}
//                   </button>
//                 </div>
//               ))
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Search;










// current --------------------------------

// import React, { useEffect, useCallback } from 'react';
// import { FaSearch, FaTimes, FaSpinner, FaUserAltSlash } from 'react-icons/fa';
// import { useDispatch, useSelector } from 'react-redux';
// import debounce from 'lodash.debounce';
// import { searchUsers, clearSearch, followOrUnfollowUser } from '../../components/redux/slices/searchSlice';
// import { Link } from 'react-router-dom';

// const Search = () => {
//   const dispatch = useDispatch();
//   const { users, loading, error, loadingFollow } = useSelector((state) => state.search);

//   const [inputValue, setInputValue] = React.useState('');

//   // Debounced search function
//   const debouncedSearch = useCallback(
//     debounce((value) => {
//       if (value.trim()) {
//         dispatch(searchUsers(value));
//       }
//     }, 300),
//     [dispatch]
//   );

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setInputValue(value);
    
//     // If the input is cleared, reset the users and stop API calls
//     if (value.trim() === '') {
//       dispatch(clearSearch()); // Clears the user list and error
//     } else {
//       debouncedSearch(value);
//     }
//   };

//   const clearInput = () => {
//     setInputValue('');
//     dispatch(clearSearch()); // Clears the user list and error
//   };

//   const handleFollowOrUnfollow = (userId, isFollowing) => {
//     dispatch(followOrUnfollowUser({ userId, isFollowing }));
//   };

//   return (
//     <div className="max-w-xl mx-auto h-screen flex flex-col">
//       <div className="flex justify-center flex-grow bg-neutral-800 text-white  md:p-2 rounded-md flex-col">
//         <div className="bg-black rounded-lg flex flex-col flex-grow shadow-lg">
//           {/* Search Input UI */}
//           <div className="relative mx-4 mt-4 md:mx-6">
//           <input
//               type="text"
//               value={inputValue}
//               onChange={handleInputChange}
//               placeholder="Search users..."
//               className="w-full p-2 pl-10 pr-10 border-none rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300 ease-in-out"
//             />
//             <FaSearch className="absolute top-3 left-3 text-gray-600 animate-pulse" />
//             {inputValue && (
//               <FaTimes
//                 className="absolute top-3 right-3 text-gray-600 cursor-pointer"
//                 onClick={clearInput}
//               />
//             )}
//           </div>

//           {/* Error Handling */}
//           {error && <p className="text-red-500 text-center mx-4 mt-4">{error}</p>}

//            {/* User List */}
         
//            <div className="flex-grow overflow-y-auto mx-4 mt-4 mb-4 md:mx-6 h-64">
//             {loading ? (
//               <div className="flex items-center justify-center h-full">
//                 <FaSpinner className="text-gray-400 animate-spin" size={40} />
//               </div>
//             ) : users.length === 0 ? (
//               <div className="flex flex-col items-center justify-center h-full">
//                 <FaUserAltSlash className="text-gray-400 animate-bounce" size={60} />
//                 <p className="text-gray-400 mt-4">No users found.</p>
//               </div>
//             ) : (
//               users.map((user) => (
//                 <div
//                   key={user.id}
//                   className="rounded-lg p-2 px-4 flex items-center justify-between mb-3 shadow-md bg-neutral-700 hover:bg-neutral-600 transition duration-300"
//                 >
//                   <div className="flex items-start gap-4 cursor-pointer">
//                     <img
//                       src={user.profilePicture || 'https://parkridgevet.com.au/wp-content/uploads/2020/11/Profile-300x300.png'}
//                       alt={user.userName}
//                       className="h-12 w-12 rounded-full object-cover"
//                     />
//                     <Link to={`/user/profile/${user.id}`}>
//                       <div className="h-full flex flex-col items-start">
//                         <span className="text-md text-white">{user.userName}</span>
//                         <span className="text-xs text-gray-300">{user.firstName} {user.lastName}</span>
//                       </div>
//                     </Link>
//                   </div>
//                   <button
//                     className={`ml-2 px-4 py-1.5 border text-sm rounded-md transition duration-300 ${
//                       user.isFollowing ? 'bg-gray-500 text-white' : 'bg-blue-600 text-white'
//                     }`}
//                     onClick={() => handleFollowOrUnfollow(user.id, user.isFollowing)}
//                     disabled={loadingFollow[user.id]}
//                   >
//                     {loadingFollow[user.id] ? (
//                       <FaSpinner className="animate-spin" />
//                     ) : (
//                       user.isFollowing ? 'Following' : 'Follow'
//                     )}
//                   </button>
//                 </div>
//               ))
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Search;


// cur--------------------
import React, { useEffect, useCallback, useMemo } from 'react';
import { FaSearch, FaTimes, FaSpinner, FaUserAltSlash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { searchUsers, clearSearch, followOrUnfollowUser } from '../../components/redux/slices/searchSlice';
import { Link } from 'react-router-dom';

const Search = () => {
  const dispatch = useDispatch();
  const { users, loading, error, loadingFollow } = useSelector((state) => state.search);
  const [inputValue, setInputValue] = React.useState('');

  // Memoized debounced search function
  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        if (value.trim()) {
          dispatch(searchUsers(value));
        } else {
          dispatch(clearSearch());
        }
      }, 300),
    [dispatch]
  );

  // Handle input change
  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.value;
      setInputValue(value);
      debouncedSearch(value);
    },
    [debouncedSearch]
  );

  // Clear input and search state
  const clearInput = useCallback(() => {
    setInputValue('');
    debouncedSearch.cancel(); // Cancel any pending debounce
    dispatch(clearSearch());
  }, [debouncedSearch, dispatch]);

  // Handle follow/unfollow action
  const handleFollowOrUnfollow = useCallback(
    (userId, isFollowing) => {
      dispatch(followOrUnfollowUser({ userId, isFollowing }));
    },
    [dispatch]
  );

  // Cleanup debounce on unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  // Skeleton Loader Component
  const SkeletonLoader = () => (
    <div className="space-y-4">
      {[...Array(5)].map((_, index) => (
        <div key={index} className="flex items-center justify-between p-3 bg-neutral-800 rounded-xl animate-pulse">
          <div className="flex items-center gap-3 flex-grow">
            <div className="h-10 w-10 rounded-full bg-neutral-700" />
            <div className="flex flex-col gap-2">
              <div className="h-4 w-40 bg-neutral-700 rounded" />
              <div className="h-3 w-20 bg-neutral-700 rounded" />
            </div>
          </div>
          <div className="h-8 w-20 bg-neutral-700 rounded-lg" />
        </div>
      ))}
    </div>
  );

  return (
    <div className="max-w-xl mx-auto h-screen flex flex-col bg-neutral-600">
      <div className="flex justify-center flex-grow bg-neutral-800 text-white  flex-col">
        <div className="bg-black rounded-lg flex flex-col flex-grow shadow-lg">
          {/* Search Input UI */}
          <div className="relative mx-4 mt-4 md:mx-6">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Search users..."
              className="w-full p-2 pl-10 pr-10 border-none rounded-lg text-white bg-neutral-600 focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300 ease-in-out"
            />
            <FaSearch className="absolute top-1/2 left-3 transform -translate-y-1/2 text-white" />
            {inputValue && (
              <FaTimes
                className="absolute top-1/2 right-3 transform -translate-y-1/2 text-gray-100 cursor-pointer"
                onClick={clearInput}
              />
            )}
          </div>

          {/* User List */}
          <div className="flex-grow overflow-y-auto mx-4 mt-4 mb-4 md:mx-6 max-h-[calc(100vh-120px)] scrollbar-thin scrollbar-thumb-gray-600 scrollbar-track-gray-800">
            {loading ? (
              <SkeletonLoader />
            ) : error ? (
              <div className="flex flex-col items-center justify-center h-full text-gray-400">
                <FaUserAltSlash className="animate-bounce" size={60} />
                <p className="mt-4 text-sm sm:text-base">{error}</p>
              </div>
            ) : users.length === 0 && inputValue.trim() !== '' ? (
              <div className="flex flex-col items-center justify-center h-full text-gray-400">
                <FaUserAltSlash className="animate-bounce" size={60} />
                <p className="mt-4 text-sm sm:text-base">No users found.</p>
              </div>
            ) : users.length === 0 ? (
              <div className="flex flex-col items-center justify-center h-full text-gray-400">
                <FaUserAltSlash className="animate-bounce" size={60} />
                <p className="mt-4 text-sm sm:text-base">Start typing to search for users.</p>
              </div>
            ) : (
              users.map((user) => (
                <div
                  key={user.id}
                  className="rounded p-2 flex items-center justify-between mb-3 shadow-md hover:bg-neutral-600 transition duration-300"
                >
                  <div className="flex items-start gap-4 cursor-pointer">
                    <img
                      src={
                        user.profilePicture ||
                        'https://parkridgevet.com.au/wp-content/uploads/2020/11/Profile-300x300.png'
                      }
                      alt={user.userName}
                      className="h-12 w-12 rounded-full object-cover"
                      onError={(e) =>
                        (e.target.src =
                          'https://parkridgevet.com.au/wp-content/uploads/2020/11/Profile-300x300.png')
                      }
                    />
                    <Link to={`/user/profile/${user.id}`} className="hover:underline">
                      <div className="flex flex-col items-start">
                        <span className="text-sm text-white">{user.userName}</span>
                        <span className="text-xs text-gray-300">
                          {user.firstName} {user.lastName}
                        </span>
                      </div>
                    </Link>
                  </div>
                  <button
                    className={`ml-2 px-4 py-1.5 border text-xs rounded-md transition duration-300 ${
                      user.isFollowing
                        ? 'bg-gray-500 text-white hover:bg-gray-600'
                        : 'bg-[#56b5f4] text-white hover:bg-[#468dc7]'
                    }`}
                    onClick={() => handleFollowOrUnfollow(user.id, user.isFollowing)}
                    disabled={loadingFollow[user.id]}
                  >
                    {loadingFollow[user.id] ? (
                      <FaSpinner className="animate-spin" />
                    ) : user.isFollowing ? (
                      'Following'
                    ) : (
                      'Follow'
                    )}
                  </button>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
















// import React, { useEffect, useCallback } from 'react';
// import { FaSearch, FaTimes, FaSpinner, FaUserAltSlash } from 'react-icons/fa';
// import { useDispatch, useSelector } from 'react-redux';
// import debounce from 'lodash.debounce';
// import { searchUsers, clearSearch, followOrUnfollowUser } from '../../components/redux/slices/searchSlice';
// import { Link } from 'react-router-dom';

// const Search = () => {
//   const dispatch = useDispatch();
//   const { users, loading, error, loadingFollow } = useSelector((state) => state.search);

//   const [inputValue, setInputValue] = React.useState('');

//   // Debounced search function
//   const debouncedSearch = useCallback(
//     debounce((value) => {
//       if (value.trim()) {
//         dispatch(searchUsers(value));
//       }
//     }, 300), // Adjust debounce delay for smoother search
//     [dispatch]
//   );

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     setInputValue(value);

//     // If the input is cleared, reset the users and stop API calls
//     if (value.trim() === '') {
//       dispatch(clearSearch());
//     } else {
//       debouncedSearch(value);
//     }
//   };

//   const clearInput = () => {
//     setInputValue('');
//     dispatch(clearSearch());
//   };

//   const handleFollowOrUnfollow = (userId, isFollowing) => {
//     dispatch(followOrUnfollowUser({ userId, isFollowing }));
//   };

//   return (
//     <div className="max-w-xl mx-auto h-full flex flex-col">
//       {/* Container */}
//       <div className="flex justify-center flex-grow bg-neutral-800 text-white md:p-4 p-2 rounded-md flex-col">
//         <div className="bg-black rounded-lg flex flex-col flex-grow shadow-lg">
          
//           {/* Search Input UI */}
//           <div className="relative mx-4 mt-4 md:mx-6">
            // <input
            //   type="text"
            //   value={inputValue}
            //   onChange={handleInputChange}
            //   placeholder="Search users..."
            //   className="w-full p-2 pl-10 pr-10 border-none rounded-lg text-black focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-300 ease-in-out"
            // />
//             <FaSearch className="absolute top-3 left-3 text-gray-600" />
//             {inputValue && (
//               <FaTimes
//                 className="absolute top-3 right-3 text-gray-600 cursor-pointer"
//                 onClick={clearInput}
//               />
//             )}
//           </div>

//           {/* Error Handling */}
//           {error && <p className="text-red-500 text-center mx-4 mt-4">{error}</p>}

//           {/* User List */}
//           <div className="flex-grow overflow-y-auto mx-4 mt-4 mb-4 md:mx-6">
//             {loading ? (
//               <div className="flex items-center justify-center h-full">
//                 <FaSpinner className="text-gray-400 animate-spin" size={40} />
//               </div>
//             ) : users.length === 0 ? (
//               <div className="flex flex-col items-center justify-center h-full">
//                 <FaUserAltSlash className="text-gray-400 animate-bounce" size={60} />
//                 <p className="text-gray-400 mt-4">No users found.</p>
//               </div>
//             ) : (
//               users.map((user) => (
//                 <div
//                   key={user.id}
//                   className="rounded-lg p-2 px-4 flex items-center justify-between mb-3 shadow-md bg-neutral-700 hover:bg-neutral-600 transition duration-300"
//                 >
//                   <div className="flex items-start gap-4 cursor-pointer">
//                     <img
//                       src={user.profilePicture || 'https://parkridgevet.com.au/wp-content/uploads/2020/11/Profile-300x300.png'}
//                       alt={user.userName}
//                       className="h-12 w-12 rounded-full object-cover"
//                     />
//                     <Link to={`/user/profile/${user.id}`}>
//                       <div className="h-full flex flex-col items-start">
//                         <span className="text-md text-white">{user.userName}</span>
//                         <span className="text-xs text-gray-300">{user.firstName} {user.lastName}</span>
//                       </div>
//                     </Link>
//                   </div>
//                   <button
//                     className={`ml-2 px-4 py-1.5 border text-sm rounded-md transition duration-300 ${
//                       user.isFollowing ? 'bg-gray-500 text-white' : 'bg-blue-600 text-white'
//                     }`}
//                     onClick={() => handleFollowOrUnfollow(user.id, user.isFollowing)}
//                     disabled={loadingFollow[user.id]}
//                   >
//                     {loadingFollow[user.id] ? (
//                       <FaSpinner className="animate-spin" />
//                     ) : (
//                       user.isFollowing ? 'Following' : 'Follow'
//                     )}
//                   </button>
//                 </div>
//               ))
//             )}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Search;
