
import React from "react";
import MobileNav from "../MainHeader/MobileNav";
import LargeNav from "../MainHeader/LargeNav";
import { Outlet } from 'react-router-dom';
const Home = () => {
  return (
    <div className="min-h-screen bg-black flex flex-col">
      {/* Sidebar for large screens */}
      <div className="hidden md:block fixed top-0 left-0 h-full w-16 md:w-20 lg:w-64 z-40">
        <LargeNav />
      </div>

      {/* Main content area */}
      <main className="flex-1 md:ml-16 lg:ml-64 pb-16 md:pb-0">
      <Outlet/>
      </main>

      {/* Bottom Navbar for mobile screens */}
      <div className="md:hidden fixed bottom-0 left-0 w-full z-50 bg-black">
        <MobileNav />
      </div>
    </div>
  );
};

export default Home;








// import React from 'react';
// import { Outlet } from 'react-router-dom';
// import Home from '../MainHeader/Home';

// const DefaultLayout = () => {
//   return (
//     <div className="flex flex-col md:flex-row">
//       {/* Sidebar for larger screens */}
//       <Home />
//       {/* Main content area */}
//       <main className="flex-grow bg-black text-white sm:m-0 md:ml-20 lg:ml-64">
//         <div className="px-4  md:px-6 lg:px-10 sm:p-0">
//           <Outlet />
//         </div>
//       </main>
//     </div>
//   );
// };

// export default DefaultLayout;



