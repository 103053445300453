// import React, { useState } from "react";
// import { NavLink, useLocation } from "react-router-dom";
// import { FaHome, FaCompass, FaHeart, FaUser, FaSearch, FaUpload } from "react-icons/fa";
// import VideoUploadModal from "./VideoUploadModal"; // Import your VideoUploadModal component

// const MobileNav = () => {
//   const { pathname } = useLocation();
//   const [videoUploadModalOpen, setVideoUploadModalOpen] = useState(false); // State for the upload modal

//   const navLinks = [
//     { to: "/reels", icon: <FaHome />, label: "Home" },
//     { to: "/search", icon: <FaSearch />, label: "Search" },
//     { to: "/explore", icon: <FaCompass />, label: "Explore" },
//     { to: "#", icon: <FaUpload />, label: "Upload", onClick: () => setVideoUploadModalOpen(true) }, 
//     { to: "/notification", icon: <FaHeart />, label: "Notifications" },
//     { to: "/profile", icon: <FaUser Circle />, label: "Profile" },
//   ];

//   return (
//     <>
//       <nav
//         className="fixed bottom-0 left-0 w-full bg-black text-white shadow-lg flex justify-around items-center py-2 md:hidden z-40 transition-all duration-300 ease-in-out"
//         role="navigation"
//         aria-label="Mobile navigation"
//       >
//         {navLinks.map(({ to, icon, label, onClick }) => (
//           <NavLink
//             key={label}
//             to={to}
//             onClick={onClick} // Handle click for upload button
//             className={({ isActive }) =>
//               `flex items-center justify-center p-3 rounded-full transition-colors duration-200 ${
//                 isActive ? "text-[#56b5f4] bg-neutral-800 scale-110" : "text-white hover:text-[#56b5f4] hover:bg-neutral-700"
//               }`
//             }
//             aria-label={label}
//             aria-current={pathname === to ? "page" : undefined}
//           >
//             {React.cloneElement(icon, {
//               size: window.innerWidth < 360 ? 18 : 22, // Responsive icon size
//               className: "transition-transform duration-200 group-hover:scale-125",
//             })}
//           </NavLink>
//         ))}
//       </nav>

//       {/* Video Upload Modal */}
//       {videoUploadModalOpen && (
//         <VideoUploadModal onRequestClose={() => setVideoUploadModalOpen(false)} />
//       )}
//     </>
//   );
// };

// export default MobileNav;


import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaHome, FaCompass, FaHeart, FaUser , FaSearch, FaUpload } from "react-icons/fa";
import VideoUploadModal from "./VideoUploadModal"; // Import your VideoUploadModal component

const MobileNav = () => {
  const { pathname } = useLocation();
  const [videoUploadModalOpen, setVideoUploadModalOpen] = useState(false); // State for the upload modal

  const navLinks = [
    { to: "/reels", icon: <FaHome />, label: "Home" },
    { to: "/search", icon: <FaSearch />, label: "Search" },
    { to: "/explore", icon: <FaCompass />, label: "Explore" },
    { to: "#", icon: <FaUpload />, label: "Upload", onClick: () => setVideoUploadModalOpen(true) }, 
    { to: "/notification", icon: <FaHeart />, label: "Notifications" },
    { to: "/profile", icon: <FaUser  />, label: "Profile" },
  ];

  return (
    <>
      <nav
        className="fixed bottom-0 left-0 w-full bg-black text-white shadow-lg flex justify-around items-center py-2 md:hidden z-40 transition-all duration-300 ease-in-out"
        role="navigation"
        aria-label="Mobile navigation"
      >
        {navLinks.map(({ to, icon, label, onClick }) => (
          <NavLink
            key={label}
            to={to}
            onClick={onClick} // Handle click for upload button
            className={({ isActive }) =>
              `flex items-center justify-center p-3 rounded-full transition-colors duration-200 ${
                // Check if the link is active, but exclude the upload link from being marked active
                isActive && to !== "#" ? "text-[#56b5f4] bg-neutral-800 scale-110" : "text-white hover:text-[#56b5f4] hover:bg-neutral-700"
              }`
            }
            aria-label={label}
            aria-current={pathname === to ? "page" : undefined}
          >
            {React.cloneElement(icon, {
              size: window.innerWidth < 360 ? 18 : 22, // Responsive icon size
              className: "transition-transform duration-200 group-hover:scale-125",
            })}
          </NavLink>
        ))}
      </nav>

      {/* Video Upload Modal */}
      {videoUploadModalOpen && (
        <VideoUploadModal onRequestClose={() => setVideoUploadModalOpen(false)} />
      )}
    </>
  );
};

export default MobileNav;