
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";

const useOtpVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  // Get the activeTab from navigation state
  const activeTab = location.state?.activeTab || "email";
  const emailOrPhone = localStorage.getItem("email_phone");

  useEffect(() => {
    setDisabled(otp.length !== 6 || !/^\d{6}$/.test(otp));
  }, [otp]);

  const showError = (error) => {
    setErrorMsg(error);
    setTimeout(() => setErrorMsg(""), 3000);
  };

  const onOTPVerify = async (e) => {
    e.preventDefault();
    if (!/^\d{6}$/.test(otp)) {
      showError("Please enter a valid 6-digit OTP.");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_AUTH}/verifyOTP`,
        { email_phone: emailOrPhone, otp: Number(otp) }
      );

      if (response.status === 200) {
        toast.success(response.data.message || "OTP verified successfully!");
        navigate("/password-name");
      } else {
        throw new Error(response.data.message || "Unexpected error occurred");
      }
    } catch (error) {
      const message =
        error.response?.data?.message || "OTP verification failed";
      showError(message);
      toast.error(message);
      console.error("OTP verification failed", error);
    } finally {
      setLoading(false);
    }
  };

  const resendOtp = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_AUTH}/getOtp`,
        { email_phone: emailOrPhone }
      );

      if (response.status === 200) {
        toast.success("OTP resent successfully!");
      } else {
        throw new Error("Failed to resend OTP");
      }
    } catch (error) {
      const message =
        error.response?.data?.message || "Failed to resend OTP";
      showError(message);
      toast.error(message);
      console.error("OTP resend failed", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    emailOrPhone,
    activeTab,
    otp,
    loading,
    disabled,
    errorMsg,
    setOtp,
    onOTPVerify,
    resendOtp,
  };
};

export default useOtpVerification;

