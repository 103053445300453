
// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDxMgdjKFGLCA_Z5XpFnyubBvTBwZxFB-U",
  authDomain: "bloopers-1674d.firebaseapp.com",
  // authDomain: "bloopersapp.com",
  databaseURL: "https://bloopers-1674d-default-rtdb.firebaseio.com",
  projectId: "bloopers-1674d",
  storageBucket: "bloopers-1674d.appspot.com",
  messagingSenderId: "568316000114",
  appId: "1:568316000114:web:3c9ff1c8c348ce5767cfeb",
  measurementId: "G-P38PXEWNS7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const messaging = getMessaging(app);

export { app, auth, messaging, GoogleAuthProvider, signInWithPopup };







// import { initializeApp } from "firebase/app";
// import { getAuth } from 'firebase/auth';
// import { getMessaging } from 'firebase/messaging';

// const firebaseConfig = {
//   apiKey: "AIzaSyDxMgdjKFGLCA_Z5XpFnyubBvTBwZxFB-U",
//   authDomain: "bloopers-1674d.firebaseapp.com",
//   databaseURL: "https://bloopers-1674d-default-rtdb.firebaseio.com",
//   projectId: "bloopers-1674d",
//   storageBucket: "bloopers-1674d.appspot.com",
//   messagingSenderId: "568316000114",
//   appId: "1:568316000114:web:d285bc60453db80c67cfeb",
//   measurementId: "G-FESH53LY04"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
// const messaging = getMessaging(app);

// export { app, auth, messaging };




