// import React, { useState } from 'react';
// import { NavLink, useLocation } from 'react-router-dom';
// import { FaHome, FaSearch, FaCompass, FaHeart, FaUser } from 'react-icons/fa';
// import { FiLogOut } from 'react-icons/fi';
// import logo from '../../assets/Images/logo.png';
// import Icon from '../../assets/Images/bloopers.png';
// import LogoutModal from './LogoutModal';

// const LargeNav = () => {
//   const { pathname } = useLocation();
//   const [logoutModalOpen, setLogoutModalOpen] = useState(false);

//   const navLinks = [
//     { to: '/reels', icon: <FaHome size={20} />, label: 'Home' },
//     { to: '/search', icon: <FaSearch size={20} />, label: 'Search' },
//     { to: '/explore', icon: <FaCompass size={20} />, label: 'Explore' },
//     { to: '/notification', icon: <FaHeart size={20} />, label: 'Notifications' },
//     { to: '/profile', icon: <FaUser size={20} />, label: 'Profile' },
//   ];

//   return (
//     <aside className="fixed left-0 top-0 z-40 flex h-screen w-16 md:w-20 lg:w-64 flex-col shadow-2xl bg-black text-white border-r-2 border-neutral-600 transition-transform duration-300 ease-linear lg:static lg:translate-x-0">
//       {/* Logo section */}
//       <div className="flex items-center justify-center lg:justify-start px-2 py-4">
//         <NavLink to="#" className="flex justify-center">
//           <img src={Icon} alt="Icon" className="md:hidden w-10 h-auto" />
//           <img src={logo} alt="Logo" className="hidden md:block md:w-16 lg:w-28 h-auto" />
//         </NavLink>
//       </div>

//       {/* Sidebar Navigation Links */}
//       <nav className="flex flex-col flex-grow space-y-3 px-2 md:px-4 py-4 overflow-y-auto no-scrollbar">
//         {navLinks.map(({ to, icon, label }) => (
//           <NavLink
//             key={to}
//             to={to}
//             className={`group flex items-center justify-center lg:justify-start px-4 py-3 rounded-lg transition-colors duration-300 ${
//               pathname === to
//                 ? 'bg-[#56b5f4] text-white dark:bg-[#1f2560] font-semibold'
//                 : 'text-white hover:bg-neutral-700 font-normal'
//             }`}
//           >
//             <span className="mr-0 lg:mr-4 group-hover:scale-125 group-hover:rotate-12 transition-transform duration-300">
//               {icon}
//             </span>
//             <span className="hidden lg:block">{label}</span>
//           </NavLink>
//         ))}
//       </nav>

//       {/* Logout Button Positioned at the Bottom */}
//       <div className="px-4 py-4 mt-auto">
//         <button
//           className="flex items-center justify-center lg:justify-start gap-2 px-4 py-3 text-red-500 hover:text-red-600 w-full transition-colors duration-300"
//           onClick={() => setLogoutModalOpen(true)}
//         >
//           <FiLogOut size={25} />
//           <span className="hidden lg:block font-mono font-semibold">Logout</span>
//         </button>
//       </div>

//       {logoutModalOpen && <LogoutModal onRequestClose={() => setLogoutModalOpen(false)} />}
//     </aside>
//   );
// };

// export default LargeNav;







import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { FaHome, FaSearch, FaCompass, FaHeart, FaUser } from "react-icons/fa";
import { FiLogOut, FiUpload } from "react-icons/fi";
import logo from "../../assets/Images/logo.png";
import Icon from "../../assets/Images/bloopers.png";
import LogoutModal from "./LogoutModal";
import VideoUploadModal from "./VideoUploadModal";

const LargeNav = () => {
  const { pathname } = useLocation();
  const [logoutModalOpen, setLogoutModalOpen] = useState(false);
  const [videoUploadModalOpen, setVideoUploadModalOpen] = useState(false);

  const navLinks = [
    { to: "/reels", icon: <FaHome size={20} />, label: "Home" },
    { to: "/search", icon: <FaSearch size={20} />, label: "Search" },
    { to: "/explore", icon: <FaCompass size={20} />, label: "Explore" },
    { to: "/notification", icon: <FaHeart size={20} />, label: "Notifications" },
    { to: "/profile", icon: <FaUser size={20} />, label: "Profile" },
  ];

  // Handle closing modals with Escape key for accessibility
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        setLogoutModalOpen(false);
        setVideoUploadModalOpen(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  return (
    <aside
      className="fixed left-0 top-0 z-40 h-screen w-16 md:w-20 lg:w-64 flex flex-col shadow-2xl bg-black text-white border-r-[1px] border-neutral-600 transition-all duration-300 ease-in-out"
    >
      {/* Logo section */}
      <div className="flex items-center justify-center lg:justify-start px-2 py-4">
        <NavLink to="/reels" className="flex justify-center">
          <img src={Icon} alt="Icon" className="md:hidden w-10 h-auto" />
          <img
            src={logo}
            alt="Logo"
            className="hidden md:block md:w-16 lg:w-28 h-auto"
          />
        </NavLink>
      </div>

      {/* Navigation Links */}
      <nav className="flex-grow space-y-3 px-2 md:px-4 py-4 overflow-y-auto scrollbar-hide">
        {navLinks.map(({ to, icon, label }) => (
          <NavLink
            key={to}
            to={to}
            className={({ isActive }) =>
              `group flex items-center justify-center lg:justify-start px-4 py-3 rounded-xl transition-colors duration-300 ${
                isActive
                  ? "text-[#56b5f4] dark:bg-[#1f2560] font-semibold"
                  : "text-white hover:bg-neutral-700 font-normal"
              }`
            }
          >
            <span className="mr-0 lg:mr-4 group-hover:scale-125 group-hover:rotate-12 transition-transform duration-300">
              {icon}
            </span>
            <span className="hidden lg:block">{label}</span>
          </NavLink>
        ))}
      </nav>

      {/* Upload Video Button */}
      <div className="px-4 py-2">
        <button
          className="flex items-center justify-center lg:justify-start gap-2 px-4 py-3 text-white hover:text-gray-400 w-full rounded-xl transition-colors duration-300"
          onClick={() => setVideoUploadModalOpen(true)}
        >
          <FiUpload size={25} />
          <span className="hidden lg:block font-mono font-semibold">
            Upload Video
          </span>
        </button>
      </div>

      {/* Logout Button */}
      <div className="px-4 py-4 mt-auto">
        <button
          className="flex items-center justify-center lg:justify-start gap-2 px-4 py-3 text-red-500 hover:text-red-600 w-full rounded-xl transition-colors duration-300"
          onClick={() => setLogoutModalOpen(true)}
        >
          <FiLogOut size={25} />
          <span className="hidden lg:block font-mono font-semibold">
            Logout
          </span>
        </button>
      </div>

      {/* Modals */}
      {logoutModalOpen && ( <LogoutModal onRequestClose={() => setLogoutModalOpen(false)} /> )}
      {videoUploadModalOpen && ( <VideoUploadModal onRequestClose={() => setVideoUploadModalOpen(false)} /> )}
    </aside>
  );
};

// Custom CSS for hiding scrollbar (cross-browser compatible)
const styles = `
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }`;
const StyleSheet = () => (
  <style dangerouslySetInnerHTML={{ __html: styles }} />
);

export default () => (
  <>
    <StyleSheet />
    <LargeNav />
  </>
);