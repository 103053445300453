// import { useState, useRef } from "react";
// import { BiArrowBack } from "react-icons/bi";
// import { FaPause, FaPhotoVideo, FaPlay } from "react-icons/fa";
// import { FiLoader } from "react-icons/fi";
// import { IoMdCheckmarkCircleOutline } from "react-icons/io";
// import { VscClose as CloseIcon } from "react-icons/vsc";
// import axios from "axios";
// import toast, { Toaster } from "react-hot-toast";

// const VideoUploadModal = ({ onRequestClose }) => {
//   const [videoDuration, setVideoDuration] = useState(0);
//   const [error, setError] = useState(null);
//   const [hasFile, setFile] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [shareSuccess, setShareSuccess] = useState(false);
//   const [boxStyle, setBoxStyle] = useState({ width: "35.5%" });
//   const [thumbnailIds, setThumbnailIds] = useState(null);
//   const [videoIds, setVideoIds] = useState(null);
//   const videoRef = useRef(null);


//   const reset = () => {
//     setBoxStyle({ width: "35.5%" });
//     setFile(null);
//     setVideoDuration(0);
//     setShareSuccess(false);
//     setIsLoading(false);
//     setError(null);
//     setThumbnailIds(null);
//     setVideoIds(null);
//   };


//   const handleFileSelect = (e) => {
//     const selectedFile = e.target.files[0];
//     handleFileValidation(selectedFile);
//   };

//   const handleFileDrop = (e) => {
//     e.preventDefault();
//     const file = e.dataTransfer.files[0];
//     handleFileValidation(file);
//   };

//   const handleFileValidation = (file) => {
//     if (!file) {
//       toast.error("No file selected.");
//       setError("No file selected.");
//       return;
//     }
//     if (!file.type.startsWith("video/")) {
//       toast.error("Only video files are allowed.");
//       setError("Only video files are allowed.");
//       return;
//     }
//     validateVideoDuration(file);
//   };

//   const validateVideoDuration = (file) => {
//     const videoElement = document.createElement("video");
//     videoElement.preload = "metadata";

//     videoElement.onloadedmetadata = () => {
//       window.URL.revokeObjectURL(videoElement.src);
//       const duration = videoElement.duration;
//       if (duration > 60) {
//         toast.error("Video duration must be less than 1 minute.");
//         setError("Video duration must be less than 1 minute.");
//         setFile(null);
//       } else {
//         setFile(file);
//         setVideoDuration(duration);
//         setError(null);
//       }
//     };
//     videoElement.src = URL.createObjectURL(file);
//   };

//   const generateThumbnail = (videoFile, frameTime = 2) => {
//     return new Promise((resolve, reject) => {
//       const canvas = document.createElement("canvas");
//       const videoElement = document.createElement("video");

//       videoElement.src = URL.createObjectURL(videoFile);
//       videoElement.crossOrigin = "anonymous";

//       videoElement.onloadedmetadata = () => {
//         videoElement.currentTime = Math.min(frameTime, videoElement.duration / 2);
//       };

//       videoElement.onseeked = () => {
//         canvas.width = videoElement.videoWidth;
//         canvas.height = videoElement.videoHeight;

//         const ctx = canvas.getContext("2d");
//         ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

//         canvas.toBlob(
//           (blob) => {
//             if (blob) {
//               resolve(blob);
//             } else {
//               reject(new Error("Failed to generate thumbnail"));
//             }
//           },
//           "image/jpeg",
//           0.9
//         );
//       };

//       videoElement.onerror = (error) => {
//         reject(error);
//       };
//     });
//   };

//   const uploadThumbnail = async (thumbnailBlob) => {
//     const formData = new FormData();
//     formData.append("file", thumbnailBlob, "thumbnail.jpg");
//     try {
//         const response = await axios.post(`${process.env.REACT_APP_THUMBNAIL_UPLOAD_URL}/upload/thumb`, formData, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
//         const thumbnailId = response.data[0];
//         setThumbnailIds(thumbnailId);
//         return thumbnailId;
//       } else {
//         throw new Error("Thumbnail upload failed or invalid response format.");
//       }
//     } catch (error) {
//       console.error("Error uploading thumbnail:", error);
//       toast.error("Failed to upload thumbnail.");
//       throw error;
//     }
//   };

//   const uploadVideo = async (videoFile) => {
//     const formData = new FormData();
//     formData.append("file", videoFile);

//     try {
//       const response = await axios.post(`${process.env.REACT_APP_VIDEO_UPLOAD_URL}/upload/video`, formData, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           "Content-Type": "multipart/form-data",
//         },
//       });
//       // const extension = videoFile.name.split(".").pop();  
//       if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
//         const videoId = response.data[0];
//         setVideoIds(videoId);
//         return videoId;
//       } else {
//         throw new Error("Video upload failed or invalid response format.");
//       }
//     } catch (error) {
//       console.error("Error uploading video:", error);
//       toast.error("Failed to upload video.");
//       throw error;
//     }
//   };  
//   const handleShare = async () => {
//     if (!hasFile) {
//       setError("No file selected.");
//       return;
//     }
//     setIsLoading(true);

//     try {     
//       const thumbnailBlob = await generateThumbnail(hasFile);
//       const thumbnailId = await uploadThumbnail(thumbnailBlob);
//       const videoResponse = await uploadVideo(hasFile);
//       const videoId = videoResponse.split(".")[0];  
//       const requestPayload = {
//         allowComment: "1",
//         allowDownload: "1",
//         allowDuet: "1",
//         caption: "",
//         comments: 0,
//         downloads: 0,
//         hashtags: [],
//         isDuet: "0",
//         status: 1,    
//         thumbUrlCompress:`${process.env.REACT_APP_S3_BUCKET_URL}/${thumbnailId}`,
//         thumbUrl:`${process.env.REACT_APP_S3_BUCKET_URL}/${thumbnailId}`,
//         videoUrl: videoId,
//       };
//       const response = await axios.post(`${process.env.REACT_APP_BASE_URL_VIDEO}/upload`, requestPayload, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//           "Content-Type": "application/json",
//         },
//       });
//       if (response.status === 200) {
//         setIsLoading(false);
//         setShareSuccess(true);
//         toast.success("Video uploaded successfully!");
//         onRequestClose();
//       } else {
//         throw new Error("Failed to share video.");
//       }
//     } catch (error) {
//       console.error("Error in video sharing process:", error);
//       toast.error("Failed to upload video.");
//       setIsLoading(false);
//     }
//   };

//   const SelectFileComponent = () => {
//     return !hasFile ? (
//       <div
//       className="flex flex-col items-center justify-center flex-grow w-full gap-6 p-4"
//       onDrop={handleFileDrop}
//       onDragOver={(e) => e.preventDefault()}
//     >
//       <FaPhotoVideo size={60} className="text-neutral-400" />
//       <span className="font-light text-lg text-neutral-300">
//         Drag and drop videos here
//       </span>
//       <div className="relative">
//         <button className="bg-blue-600 w-[200px] rounded-md text-sm font-semibold flex justify-center items-center h-9 text-white hover:bg-blue-500 transition duration-200">
//           Select from computer
//         </button>
//         <input
//           onChange={handleFileSelect}
//           type="file"
//           accept="video/*"
//           className="absolute inset-0 opacity-0 cursor-pointer"
//         />
//       </div>
//       {error && <span className="text-red-500 text-sm">{error}</span>}
//     </div>
//   ) : (
//     <div className="relative w-full h-full flex items-center justify-center">
//       <video
//         src={URL.createObjectURL(hasFile)}
//         playsInline
//         controls
//         className="w-full h-full max-h-[calc(100vh-100px)] object-contain rounded-lg"
//       />
//     </div>
//     );
//   };

//   const ButtonComponent = () => {
//     return hasFile ? (
//       <div className="flex items-center justify-between w-full px-4">
//       <button onClick={reset} className="flex items-center text-neutral-300 hover:text-white transition duration-200">
//         <BiArrowBack size={24} />
//       </button>
//       <span className="text-neutral-200 font-semibold">Upload Video</span>
//       <button
//         onClick={handleShare}
//         className={`text-sm font-semibold ${isLoading ? "text-neutral-500" : "text-blue-500 hover:text-blue-400"} transition duration-200`}
//         disabled={isLoading}
//       >
//         {isLoading ? "Uploading..." : "Upload"}
//       </button>
//     </div>
//     ) : null;
//   };

//   const SuccessComponent = () => {
//     if (isLoading) {
//       return (
//         <div className="flex flex-col items-center justify-center w-full gap-6 p-4">
//         <FiLoader size={60} className="text-neutral-400 animate-spin" />
//         <span className="text-neutral-300 font-light text-lg">Uploading...</span>
//       </div>
//       );
//     }
//     if (shareSuccess) {
//       return (
//        <div className="flex flex-col items-center justify-center w-full gap-6 p-4">
//         <IoMdCheckmarkCircleOutline size={60} className="text-green-500" />
//         <span className="text-green-500 font-light text-lg">Success!</span>
//         <span className="text-neutral-300">Your video has been shared.</span>
//       </div>
//       );
//     }
//     return null;
//   };

//   return (
//     <>
//       <div className="fixed top-0 left-0 w-screen h-screen z-50 flex items-center justify-center transition-opacity duration-300 ease-in-out">
//         <Toaster position="top-center" />
//         <div
//           onClick={onRequestClose}
//           className="absolute h-full w-full bg-black/80 backdrop-blur-xs -z-10"
//         ></div>
//       <button
//         onClick={onRequestClose}
//         className="absolute top-6 right-6 text-white hover:text-white text-3xl md:text-3xl transition duration-200"
//       >
//         <CloseIcon className="animate-bounce cursor-pointer" />
//       </button>
//         <div
//           style={boxStyle}
//        className="bg-neutral-800 h-[85vh] md:h-[82%] absolute z-40 rounded-[10px] flex flex-col items-center justify-center overflow-hidden transition-all"
//         >
//           <div className="h-11 flex items-center justify-between bg-neutral-700 border-b border-neutral-700 w-full px-4 absolute z-50 top-0">
//             <ButtonComponent />
//           </div>
//           {isLoading || shareSuccess ? ( <SuccessComponent/> ) : ( <SelectFileComponent />)}
//         </div>
//       </div>
//     </>
//   );
// };
// export default VideoUploadModal;


import { useState, useRef } from "react";
import { BiArrowBack } from "react-icons/bi";
import { FaPhotoVideo } from "react-icons/fa";
import { FiLoader } from "react-icons/fi";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { VscClose as CloseIcon } from "react-icons/vsc";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
const VideoUploadModal = ({ onRequestClose }) => {
  const navigate = useNavigate();
  const [videoDuration, setVideoDuration] = useState(0);
  const [error, setError] = useState(null);
  const [hasFile, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [shareSuccess, setShareSuccess] = useState(false);
  const [thumbnailIds, setThumbnailIds] = useState(null);
  const [videoIds, setVideoIds] = useState(null);
  const videoRef = useRef(null);

  const reset = () => {
    setFile(null);
    setVideoDuration(0);
    setShareSuccess(false);
    setIsLoading(false);
    setError(null);
    setThumbnailIds(null);
    setVideoIds(null);
  };

  const handleFileSelect = (e) => {
    const selectedFile = e.target.files[0];
    handleFileValidation(selectedFile);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileValidation(file);
  };

  const handleFileValidation = (file) => {
    if (!file) {
      toast.error("No file selected.");
      setError("No file selected.");
      return;
    }
    if (!file.type.startsWith("video/")) {
      toast.error("Only video files are allowed.");
      setError("Only video files are allowed.");
      return;
    }
    validateVideoDuration(file);
  };

  const validateVideoDuration = (file) => {
    const videoElement = document.createElement("video");
    videoElement.preload = "metadata";

    videoElement.onloadedmetadata = () => {
      window.URL.revokeObjectURL(videoElement.src);
      const duration = videoElement.duration;
      if (duration > 60) {
        toast.error("Video duration must be less than 1 minute.");
        setError("Video duration must be less than 1 minute.");
        setFile(null);
      } else {
        setFile(file);
        setVideoDuration(duration);
        setError(null);
      }
    };
    videoElement.src = URL.createObjectURL(file);
  };

  const generateThumbnail = (videoFile, frameTime = 2) => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement("canvas");
      const videoElement = document.createElement("video");

      videoElement.src = URL.createObjectURL(videoFile);
      videoElement.crossOrigin = "anonymous";

      videoElement.onloadedmetadata = () => {
        videoElement.currentTime = Math.min(frameTime, videoElement.duration / 2);
      };

      videoElement.onseeked = () => {
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

        canvas.toBlob(
          (blob) => {
            if (blob) {
              resolve(blob);
            } else {
              reject(new Error("Failed to generate thumbnail"));
            }
          },
          "image/jpeg",
          0.9
        );
      };

      videoElement.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadThumbnail = async (thumbnailBlob) => {
    const formData = new FormData();
    formData.append("file", thumbnailBlob,"thumbnail.jpg");
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_THUMBNAIL_UPLOAD_URL}/upload/thumb`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
        const thumbnailId = response.data[0];
        setThumbnailIds(thumbnailId);
        return thumbnailId;
      } else {
        throw new Error("Thumbnail upload failed or invalid response format.");
      }
    } catch (error) {
      console.error("Error uploading thumbnail:", error);
      toast.error("Failed to upload thumbnail.");
      throw error;
    }
  };

  const uploadVideo = async (videoFile) => {
    const formData = new FormData();
    formData.append("file", videoFile);
    try {
      const response = await axios.post(`${process.env.REACT_APP_VIDEO_UPLOAD_URL}/upload/video`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0) {
        const videoId = response.data[0];
        setVideoIds(videoId);
        return videoId;
      } else {
        throw new Error("Video upload failed or invalid response format.");
      }
    } catch (error) {
      console.error("Error uploading video:", error);
      toast.error("Failed to upload video.");
      throw error;
    }
  };

  const handleShare = async () => {
    if (!hasFile) {
      setError("No file selected.");
      return;
    }
    setIsLoading(true);

    try {
      const thumbnailBlob = await generateThumbnail(hasFile);
      const thumbnailId = await uploadThumbnail(thumbnailBlob);
      const videoResponse = await uploadVideo(hasFile);
      const videoId = videoResponse.split(".")[0];     
      const requestPayload = {
        allowComment: "1",
        allowDownload: "1",
        allowDuet: "1",
        caption: "",
        comments: 0,
        downloads: 0,
        hashtags: [],
        isDuet: "0",
        status: 1,
        thumbUrlCompress: `${process.env.REACT_APP_S3_BUCKET_URL}/${thumbnailId}`,
        thumbUrl: `${process.env.REACT_APP_S3_BUCKET_URL}/${thumbnailId}`,
        videoUrl: videoId,
      };
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL_VIDEO}/upload`,
        requestPayload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setIsLoading(false);
        setShareSuccess(true);
        toast.success("Video uploaded successfully!");
        setTimeout(() => {
          onRequestClose();
          navigate('/profile');  // Navigate to the profile page
        }, 2000);
      } else {
        throw new Error("Failed to share video.");
      }
    } catch (error) {
      console.error("Error in video sharing process:", error);
      // toast.error("Failed to upload video.");
      setIsLoading(false);
    }
  };

  const SelectFileComponent = () => {
    return !hasFile ? (
      <div
        className="flex flex-col items-center justify-center flex-grow w-full gap-4 p-4 sm:p-6"
        onDrop={handleFileDrop}
        onDragOver={(e) => e.preventDefault()}
      >
        <FaPhotoVideo size={80} className="text-gray-400" />
        <span className="font-light text-base sm:text-lg text-gray-300 text-center">
          Drag and drop videos here
        </span>
        <div className="relative">
          <button className="bg-[#46c7f1] w-48 sm:w-52 rounded-md text-sm font-semibold flex justify-center items-center h-9 text-white hover:bg-[#3ba8d1] transition duration-200">
            Select from computer
          </button>
          <input
            onChange={handleFileSelect}
            type="file"
            accept="video/*"
            className="absolute inset-0 opacity-0 cursor-pointer"
          />
        </div>
        {error && <span className="text-red-500 text-sm text-center">{error}</span>}
      </div>
    ) : (
      <div className="relative w-full h-full flex items-center justify-center pt-4">
        <video
          ref={videoRef}
          src={URL.createObjectURL(hasFile)}
          playsInline
          controls
          className="w-full h-full max-h-[70vh] sm:max-h-[80vh] object-contain rounded-lg"
        />
      </div>
    );
  };

  const ButtonComponent = () => {
    return hasFile ? (
      <div className="flex items-center justify-between w-full px-4">
        <button
          onClick={reset}
          className="flex items-center text-gray-300 hover:text-white transition duration-200"
        >
          <BiArrowBack size={20} />
        </button>
        <span className="text-gray-200 font-semibold text-sm sm:text-base">
          Upload Video
        </span>
        <button
          onClick={handleShare}
          className={`text-sm font-semibold ${
            isLoading ? "text-gray-500 cursor-not-allowed" : "text-[#46c7f1] hover:text-[#3ba8d1]"
          } transition duration-200`}
          disabled={isLoading}
        >
          {isLoading ? "Uploading..." : "Upload"}
        </button>
      </div>
    ) : null;
  };

  const SuccessComponent = () => {
    if (isLoading) {
      return (
        <div className="flex flex-col items-center justify-center w-full gap-4 p-4 sm:p-6">
          <FiLoader size={48} className="text-gray-400 animate-spin" />
          <span className="text-gray-300 font-light text-base sm:text-lg">
            Uploading...
          </span>
        </div>
      );
    }
    if (shareSuccess) {
      return (
        <div className="flex flex-col items-center justify-center w-full gap-4 p-4 sm:p-6">
          <IoMdCheckmarkCircleOutline size={38} className="text-green-500" />
          <span className="text-green-500 font-light text-base sm:text-lg">
            Success!
          </span>
          <span className="text-gray-300 text-sm sm:text-base">
            Your video has been shared.
          </span>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className="fixed top-0 left-0 w-screen h-screen z-50 flex items-center justify-center transition-opacity duration-300 ease-in-out">
        <Toaster position="top-center" />
        <div
          onClick={onRequestClose}
          className="absolute h-full w-full bg-black/80 backdrop-blur-sm -z-10"
        ></div>
        <button
          onClick={onRequestClose}
          className="absolute top-4 right-4 sm:top-6 sm:right-6 text-white hover:text-gray-300 text-2xl sm:text-3xl transition duration-200"
        >
          <CloseIcon className="animate-bounce cursor-pointer" />
        </button>
        <div className="bg-gray-800 w-[90%] sm:w-[70%] md:w-[50%] lg:w-[35%] max-w-[600px] h-[70vh] sm:h-[80vh] md:h-[82vh] absolute z-40 rounded-lg flex flex-col items-center justify-center overflow-hidden transition-all">
          <div className="h-11 flex items-center justify-between bg-gray-700 border-b border-gray-600 w-full px-4  absolute z-50 top-0">
            <ButtonComponent />
          </div>
          {isLoading || shareSuccess ? (
            <SuccessComponent />
          ) : (
            <SelectFileComponent />
          )}
        </div>
      </div>
    </>
  );
};

export default VideoUploadModal;




// import { useState, useRef } from "react";
// import { FaPhotoVideo, FaPlay, FaPause } from "react-icons/fa";
// import { FiLoader } from "react-icons/fi";
// import { IoMdCheckmarkCircleOutline, IoMdClose } from "react-icons/io";
// import { BiArrowBack } from "react-icons/bi";


// const VideoUploadModal = ({ onRequestClose }) => {
//   const [step, setStep] = useState(1); // Step tracker
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [videoSrc, setVideoSrc] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [shareSuccess, setShareSuccess] = useState(false);

//   const videoRef = useRef(null);
//   const [isPlaying, setIsPlaying] = useState(false);

//   // Toggle Play/Pause functionality
//   const handlePlayPauseVideo = () => {
//     if (videoRef.current) {
//       if (videoRef.current.paused) {
//         videoRef.current.play();
//         videoRef.current.muted = false; // Unmute video
//         setIsPlaying(true);
//       } else {
//         videoRef.current.pause();
//         setIsPlaying(false);
//       }
//     }
//   };

//   // Handle file upload
//   const handleFileSelect = (e) => {
//     const file = e.target.files[0];
//     if (file && file.type.includes("video")) {
//       setSelectedFile(file);
//       setVideoSrc(URL.createObjectURL(file));
//       setStep(2); // Move to the video preview step
//     }
//   };

//   // Handle video sharing
//   const handleShare = () => {
//     setIsLoading(true);
//     setTimeout(() => {
//       setIsLoading(false);
//       setShareSuccess(true);
//       setStep(5); // Success step
//     }, 2000);
//   };

//   // Dynamically determine modal width
//   const getModalWidth = () => {
//     switch (step) {
//       case 1:
//         return "w-[450px]";
//       case 2:
//         return "w-[450px]";
//       case 3:
//         return "w-[450px]";
//       default:
//         return "w-[450px]";
//     }
//   };

//   return (
//     <div className="fixed top-0 left-0 w-screen h-screen z-50 flex items-center justify-center bg-black/60">
//       {/* Close Button */}
//       <button
//         onClick={onRequestClose}
//         className="absolute top-5 right-5 text-white text-3xl md:text-5xl"
//       >
//         <IoMdClose className="hover:text-red-700" />
//       </button>

//       {/* Modal Content */}
//       <div
//         className={`bg-neutral-800 text-white rounded-xl overflow-hidden ${getModalWidth()} transition-all`}
//       >
//         {/* Header */}
//         <div className="h-12 flex justify-between items-center px-4 border-b bg-neutral-700">
//           {/* Back Button */}
//           <div>
//             {step > 1 && (
//               <button onClick={() => setStep(step - 1)} title="Back">
//                 <BiArrowBack size={24} />
//               </button>
//             )}
//           </div>

//           {/* Dynamic Title */}
//           <span className="font-semibold text-lg">
//             {step === 1 && "Create new post"}
//             {step === 2 && "video preview"} 
//             {step === 3 && "vide uplaod"}
//           </span>

//           {/* Next or Share Button */}
//           <div>
//             {step < 3 && step > 1 && (
//               <button
//                 onClick={() => setStep(step + 1)}
//                 className="text-blue-500 hover:text-blue-700 font-semibold"
//               >
//                 Next
//               </button>
//             )}
//             {step === 3 && (
//               <button
//                 onClick={handleShare}
//                 className="text-blue-500 hover:text-blue-700 font-semibold"
//               >
//                uplaod
//               </button>
//             )}
//           </div>
//         </div>

//         {/* Body */}
//         <div className="h-[450px] flex justify-center items-center">
//           {/* Step 1: File Selection */}
//           {step === 1 && (
//             <div className="flex flex-col items-center gap-4">
//               <FaPhotoVideo size={80} className="text-gray-400" />
//               <p className="text-lg font-medium">Drag and drop videos here</p>
//               <div className="relative">
//                 <button className="bg-blue-500 text-sm px-4 py-1 rounded hover:bg-blue-700">
//                   Select from computer
//                 </button>
//                 <input
//                   type="file"
//                   accept="video/*"
//                   onChange={handleFileSelect}
//                   className="absolute top-0 left-0 opacity-0 w-full h-full cursor-pointer"
//                 />
//               </div>
//             </div>
//           )}

//           {/* Step 2: Video Preview */}
//           {step === 2 && (
//             <div className="relative w-full h-full">
//               <video
//                 ref={videoRef}
//                 src={videoSrc}
//                 playsInline
//                 loop
//                 muted
//                  width="100%"
//                 height="100%"
//                 className="w-full h-full object-cover cursor-pointer"
//                 onClick={handlePlayPauseVideo}
//               />
//               {/* Play/Pause Button */}
//               <button
//                 onClick={handlePlayPauseVideo}
//                 className="absolute bottom-4 left-4 bg-black bg-opacity-50 text-white p-3 rounded-full"
//               >
//                 {isPlaying ? <FaPause size={20} /> : <FaPlay size={20} />}
//               </button>
//             </div>
//           )}

        

         
//           {/* Step 5: Success */}
//           {step === 3 && (
//             <div className="flex flex-col items-center">
//               {isLoading ? (
//                 <>
//                   <FiLoader className="animate-spin text-blue-500" size={50} />
//                   <p className="mt-2">Sharing...</p>
//                 </>
//               ) : (
//                 <>
//                   <IoMdCheckmarkCircleOutline
//                     size={80}
//                     className="text-green-500"
//                   />
//                   <p className="mt-2 font-semibold">
//                     Your reel has been shared!
//                   </p>
//                 </>
//               )}
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VideoUploadModal;



// import { useState, useRef } from "react";
// import axios from "axios";
// import { FaPhotoVideo, FaPlay, FaPause } from "react-icons/fa";
// import { FiLoader } from "react-icons/fi";
// import { IoMdCheckmarkCircleOutline, IoMdClose } from "react-icons/io";
// import { BiArrowBack } from "react-icons/bi";

// const VideoUploadModal = ({ onRequestClose }) => {
//   const [step, setStep] = useState(1);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [videoSrc, setVideoSrc] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [shareSuccess, setShareSuccess] = useState(false);
//   const [videoDuration, setVideoDuration] = useState(0);
//   const [error, setError] = useState(null);

//   const videoRef = useRef(null);
//   const [isPlaying, setIsPlaying] = useState(false);

//   // Handle file selection
//   const handleFileSelect = (e) => {
//     const file = e.target.files[0];
//     if (file && file.type.startsWith("video/")) {
//       validateVideo(file);
//     } else {
//       setError("Please upload a valid video file.");
//     }
//   };

//   // Validate video duration
//   const validateVideo = (file) => {
//     const videoElement = document.createElement("video");
//     videoElement.preload = "metadata";

//     videoElement.onloadedmetadata = () => {
//       window.URL.revokeObjectURL(videoElement.src);
//       const duration = videoElement.duration;
//       if (duration > 60) {
//         setError("Video duration must be less than 1 minute.");
//       } else {
//         setSelectedFile(file);
//         setVideoSrc(URL.createObjectURL(file));
//         setVideoDuration(duration);
//         setError(null);
//         setStep(2);
//       }
//     };

//     videoElement.src = URL.createObjectURL(file);
//   };

//   // Generate thumbnail
//   const generateThumbnail = (videoFile) => {
//     return new Promise((resolve, reject) => {
//       const canvas = document.createElement("canvas");
//       const videoElement = document.createElement("video");

//       videoElement.src = URL.createObjectURL(videoFile);
//       videoElement.crossOrigin = "anonymous";

//       videoElement.onloadedmetadata = () => {
//         videoElement.currentTime = Math.min(2, videoElement.duration / 2);
//       };

//       videoElement.onseeked = () => {
//         canvas.width = videoElement.videoWidth;
//         canvas.height = videoElement.videoHeight;

//         const ctx = canvas.getContext("2d");
//         ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

//         canvas.toBlob(
//           (blob) => {
//             if (blob) resolve(blob);
//             else reject(new Error("Failed to generate thumbnail"));
//           },
//           "image/jpeg",
//           0.9
//         );
//       };

//       videoElement.onerror = reject;
//     });
//   };

//   // Upload video and thumbnail
//   const handleUpload = async () => {
//     if (!selectedFile) {
//       setError("No file selected.");
//       return;
//     }

//     setIsLoading(true);
//     try {
//       // Generate thumbnail
//       const thumbnailBlob = await generateThumbnail(selectedFile);
//       const thumbnailFormData = new FormData();
//       thumbnailFormData.append("file", thumbnailBlob, "thumbnail.jpg");

//       const thumbnailResponse = await axios.post(
//         `${process.env.REACT_APP_THUMBNAIL_UPLOAD_URL}/upload/thumb`,
//         thumbnailFormData,
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       const thumbnailId = thumbnailResponse.data[0];

//       // Upload video
//       const videoFormData = new FormData();
//       videoFormData.append("file", selectedFile);

//       const videoResponse = await axios.post(
//         `${process.env.REACT_APP_VIDEO_UPLOAD_URL}/upload/video`,
//         videoFormData,
//         {
//           headers: {
//             Authorization: `Bearer ${localStorage.getItem("token")}`,
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       const videoId = videoResponse.data[0];

//       // Post metadata
//       const payload = {
//         videoUrl: videoId,
//         thumbUrl: `${process.env.REACT_APP_S3_BUCKET_URL}/${thumbnailId}`,
//         caption: "",
//       };

//       await axios.post(`${process.env.REACT_APP_BASE_URL_VIDEO}/upload`, payload, {
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       });

//       setShareSuccess(true);
//       setStep(3);
//     } catch (error) {
//       console.error("Error uploading video:", error);
//       setError("Failed to upload video.");
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   // Play/Pause video
//   const handlePlayPauseVideo = () => {
//     if (videoRef.current) {
//       if (videoRef.current.paused) {
//         videoRef.current.play();
//         setIsPlaying(true);
//       } else {
//         videoRef.current.pause();
//         setIsPlaying(false);
//       }
//     }
//   };

//   return (
//     <div className="fixed top-0 left-0 w-screen h-screen z-50 flex items-center justify-center bg-black/60">
//       <button
//         onClick={onRequestClose}
//         className="absolute top-5 right-5 text-white text-3xl"
//       >
//         <IoMdClose />
//       </button>
//       <div className="bg-neutral-800 text-white rounded-xl w-[90%] max-w-md">
//         <div className="p-4 border-b border-neutral-700 flex justify-between items-center">
//           <span className="font-semibold">
//             {step === 1 && "Upload Video"}
//             {step === 2 && "Preview Video"}
//             {step === 3 && "Upload Successful"}
//           </span>
//           {step > 1 && (
//             <button onClick={() => setStep(step - 1)}>
//               <BiArrowBack />
//             </button>
//           )}
//         </div>
//         <div className="p-4 h-[400px] flex items-center justify-center">
//           {step === 1 && (
//             <div className="text-center">
//               <FaPhotoVideo size={50} className="text-gray-500" />
//               <p className="mt-4">Drag and drop a video here</p>
//               <input
//                 type="file"
//                 accept="video/*"
//                 onChange={handleFileSelect}
//                 className="mt-2"
//               />
//             </div>
//           )}
//           {step === 2 && videoSrc && (
//             <div className="relative w-full h-full">
//               <video
//                 ref={videoRef}
//                 src={videoSrc}
//                 className="w-full h-full object-cover"
//                 onClick={handlePlayPauseVideo}
//               />
//               <button
//                 onClick={handlePlayPauseVideo}
//                 className="absolute bottom-4 left-4 bg-black bg-opacity-50 p-2 rounded-full"
//               >
//                 {isPlaying ? <FaPause /> : <FaPlay />}
//               </button>
//             </div>
//           )}
//           {step === 3 && shareSuccess && (
//             <div className="text-center">
//               <IoMdCheckmarkCircleOutline size={50} className="text-green-500" />
//               <p className="mt-4">Video uploaded successfully!</p>
//             </div>
//           )}
//         </div>
//         {step === 2 && (
//           <div className="p-4 border-t border-neutral-700 flex justify-end">
//             <button
//               onClick={handleUpload}
//               className="bg-blue-500 px-4 py-2 rounded text-white hover:bg-blue-700"
//             >
//               {isLoading ? <FiLoader className="animate-spin" /> : "Upload"}
//             </button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default VideoUploadModal;
