import React from "react";
import logo from "../../assets/Images/logo.png";
import playStore from "../../assets/Images/play-store.png";
import appleStore from "../../assets/Images/app-store.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useNavigate } from "react-router-dom";

const SplashScreen = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen flex items-center justify-center bg-black p-4">
      <div className="flex flex-col items-center justify-center w-[350px]">
        {/* Logo Section */}
        <div className="flex flex-col items-center justify-center rounded w-full">
          <img src={logo} className="h-28 mt-4 mx-auto my-8" alt="bloopers" />
        </div>

        {/* Button Section */}
        <div className="w-full px-5">
          <div className="space-y-2 my-4">
            <button
              onClick={() => navigate("/register")}
              className="w-full max-w-xs bg-[#46c7f1] text-white font-semibold text-sm py-2 rounded-md mb-4 hover:bg-[#3ba8d1] transition-colors"
            >
              Create New Account
            </button>
            <button
              onClick={() => navigate("/login")}
              className="w-full max-w-xs text-[#46c7f1] font-semibold text-sm py-2 rounded-md border border-[#46c7f1] hover:bg-[#46c7f1] hover:text-white transition-colors"
            >
              Login
            </button>
          </div>
        </div>

        {/* App Store Links Section */}
        <div className="w-full">
          <p className="text-center m-4 text-white">Get the app.</p>
          <div className="flex items-center justify-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.bloopers"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                className="object-contain w-32 cursor-pointer mr-4 shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)]"
                src={playStore}
                alt="Play Store"
              />
            </a>
            <a
              href="https://apps.apple.com/in/app/bloopers-live-the-moment/id1639526973"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LazyLoadImage
                className="object-contain w-32 cursor-pointer shadow-[0_20px_50px_rgba(8,_112,_184,_0.7)]"
                src={appleStore}
                alt="App Store"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;