import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";

const useOtpVerification = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [otpMethod, setOtpMethod] = useState(""); // email or phone

  // Get the OTP method from localStorage
  useEffect(() => {
    const method = localStorage.getItem("otpMethod");
    setOtpMethod(method || "email");
  }, []);

  // Enable/disable submit button based on OTP length
  useEffect(() => {
    setDisabled(otp.length !== 6);
  }, [otp]);

  // Verify OTP
  const onOTPVerify = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const identifier = localStorage.getItem("email_phone");

      if (!/^\d{6}$/.test(otp)) {
        setLoading(false);
        toast.error("Please enter a valid 6-digit OTP.");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_AUTH}/verifyOTP`,
        {
          email_phone: identifier,
          otp: Number(otp), // Ensure OTP is sent as a number
        }
      );

      if (response?.status === 200) {
        setLoading(false);
        toast.success(response?.data?.message || "OTP verified successfully!");
        navigate("/reset-password"); // Redirect to reset password page
      } else if (response?.status === 400) {
        setLoading(false);
        toast.error(response?.data?.message || "Invalid OTP");
      } else {
        setLoading(false);
        toast.error("Unexpected error occurred");
      }
    } catch (error) {
      setLoading(false);
      console.error("OTP verification failed", error);
      toast.error(error.response?.data?.message || "OTP verification failed");
    }
  };

  // Resend OTP
  const resendOtp = async () => {
    try {
      setLoading(true);
      const identifier = localStorage.getItem("email_phone");
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL_AUTH}/getOtp`,
        {
          email_phone: identifier,
        }
      );

      if (response?.status === 200) {
        setLoading(false);
        toast.success("OTP resent successfully");
      } else {
        setLoading(false);
        toast.error("Failed to resend OTP");
      }
    } catch (error) {
      setLoading(false);
      console.error("OTP resend failed", error);
      toast.error(error.response?.data?.message || "OTP resend failed");
    }
  };

  return {
    otp,
    loading,
    disabled,
    otpMethod,
    setOtp,
    onOTPVerify,
    resendOtp,
  };
};

export default useOtpVerification;