import React from "react";
import { Link } from "react-router-dom";
import OtpInput from "otp-input-react";
import { ImSpinner3 as SpinnerIcon } from "react-icons/im";
import useOtpVerification from "./useOtpVerification";
import toast, { Toaster } from "react-hot-toast";
import playStore from "../../../../assets/Images/play-store.png";
import appleStore from "../../../../assets/Images/app-store.png";
import { TbPasswordMobilePhone } from "react-icons/tb";

const OtpVerified = () => {
  const {
    otp,
    loading,
    disabled,
    otpMethod,
    setOtp,
    onOTPVerify,
    resendOtp,
  } = useOtpVerification();

  const identifier = localStorage.getItem("email_phone");

  return (
    <>
      <Toaster />
      <div className="min-h-screen flex items-center justify-center p-3 bg-black">
        <div className="flex flex-col items-center w-[350px]">
          <div className="flex flex-col items-center justify-center rounded w-full border border-gray-300 p-4">
            <TbPasswordMobilePhone className="text-white w-20 h-16 mb-2" />
            <p className="mb-4 text-center font-bold text-lg text-[#fcfcfc]">
              Enter OTP
            </p>
            <p className="mb-4 text-center text-md text-[#fcfcfc]">
              Enter OTP sent to your {otpMethod === "email" ? "email address" : "phone number"}:{" "}
              <span className="font-bold">{identifier}</span>
            </p>

            <div className="w-full">
              <form onSubmit={onOTPVerify}>
                <div className="w-full mb-3 p-4">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    OTPLength={6}
                    otpType="number"
                    disabled={false}
                    autoFocus
                    className="otp-input"
                    renderInput={(props) => <input className="otp-input-field" {...props} />}
                  />
                </div>

                <div className="w-full mt-6">
                  <button
                    className={`w-full ${
                      disabled ? "bg-gray-300" : "bg-[#46c7f1]"
                    } text-xs text-[#fcfcfc] font-semibold p-1 rounded-sm`}
                    disabled={disabled || loading}
                    type="submit"
                  >
                    {loading ? (
                      <SpinnerIcon className="w-3 h-3 animate-spin my-1 mx-auto" />
                    ) : (
                      "Next"
                    )}
                  </button>
                </div>

                <div className="text-center w-full text-xs font-thin my-4 text-white">
                  <span onClick={resendOtp} className="cursor-pointer">
                    Resend OTP
                  </span>
                </div>
              </form>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center rounded w-full mt-4 p-4 border border-gray-300">
            <div className="text-sm text-[#fcfcfc]">
              Already have an account?{" "}
              <Link to="/login" className="text-[#46c7f1] font-semibold">
                Log in
              </Link>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center rounded w-full mt-4">
            <p className="text-sm text-[#fcfcfc]">Get the app</p>
            <div className="flex justify-between gap-4 mt-4">
              <a
                href="https://play.google.com/store/apps/details?id=com.bloopers"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={playStore}
                  alt="Play Store"
                  className="lg:block hidden h-14 cursor-pointer"
                />
              </a>
              <a
                href="https://apps.apple.com/in/app/bloopers-live-the-moment/id1639526973"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appleStore}
                  alt="Apple Store"
                  className="lg:block hidden h-14 cursor-pointer"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerified;