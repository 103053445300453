import React from "react";
import { Link } from "react-router-dom";
import OtpInput from "otp-input-react";
import { ImSpinner3 as SpinnerIcon } from "react-icons/im";
import { TbPasswordUser } from "react-icons/tb";
import { Toaster } from "react-hot-toast";
import useOtpVerification from "./useOtpVerification"; // Updated hook name
import playStore from "../../../../assets/Images/play-store.png";
import appleStore from "../../../../assets/Images/app-store.png";

const OtpVerifiedEmailPhone = () => {
  const {
    emailOrPhone,
    activeTab,
    otp,
    loading,
    disabled,
    errorMsg,
    setOtp,
    onOTPVerify,
    resendOtp,
  } = useOtpVerification();

  return (
    <>
      <Toaster />
      <div className="min-h-screen flex items-center justify-center p-3 bg-black">
        <div className="flex flex-col items-center w-full max-w-sm">
          <div className="flex flex-col items-center justify-center rounded w-full border border-gray-300 p-6">
            <TbPasswordUser className="text-white w-14 h-14 mb-2" />
            <p className="mb-4 text-center font-bold text-lg text-[#fcfcfc]">
              Enter OTP
            </p>
            <p className="mb-4 text-center text-md text-[#fcfcfc]">
              Enter OTP sent to your {activeTab === "email" ? "email address" : "phone number"}: <strong>{emailOrPhone}</strong>
            </p>

            <form onSubmit={onOTPVerify} className="w-full">
              <div className="w-full mb-4 ml-2">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  OTPLength={6}
                  otpType="number"
                  disabled={loading}
                  autoFocus
                  className="otp-input"
                  renderInput={(props) => (
                    <input className="otp-input-field" {...props} />
                  )}
                />
              </div>

              {errorMsg && (
                <div className="text-center text-xs my-4 text-red-600">
                  {errorMsg}
                </div>
              )}

              <button
                className={`w-full ${
                  disabled || loading ? "bg-gray-300" : "bg-[#46c7f1]"
                } text-xs text-[#fcfcfc] font-semibold p-2 mt-4 rounded-sm`}
                disabled={disabled || loading}
                type="submit"
              >
                {loading ? (
                  <SpinnerIcon className="w-4 h-4 animate-spin mx-auto" />
                ) : (
                  "Next"
                )}
              </button>

              <div className="text-center w-full text-xs font-thin my-4 text-white">
                <span
                  onClick={resendOtp}
                  className={`cursor-pointer ${
                    loading ? "text-gray-400" : "text-[#46c7f1]"
                  }`}
                >
                  Resend OTP
                </span>
              </div>
            </form>
          </div>

          <div className="flex flex-col items-center justify-center rounded w-full mt-4 border border-gray-300 p-4">
            <div className="text-sm text-[#fcfcfc]">
              Already have an account?{" "}
              <Link to="/" className="text-[#46c7f1] font-semibold">
                Log in
              </Link>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center rounded w-full mt-4">
            <p className="text-sm text-[#fcfcfc]">Get the app</p>
            <div className="flex justify-between gap-4 mt-4">
              <a
                href="https://play.google.com/store/apps/details?id=com.bloopers"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={playStore}
                  alt="Play Store"
                  className="h-12 cursor-pointer"
                />
              </a>
              <a
                href="https://apps.apple.com/in/app/bloopers-live-the-moment/id1639526973"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={appleStore}
                  alt="Apple Store"
                  className="h-12 cursor-pointer"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtpVerifiedEmailPhone;